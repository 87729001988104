import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppComponent } from './app.component';
import { appRouting } from './app.routes';
import { MaterialModule } from './modules/material.module';
import { tdrsHttpServerErrorInterceptor } from './service/common/tdrs-http-server-error.interceptor';
import { tdrsServiceModule } from './service/tdrs-service.module';
import { CustomSerializer, effects, metaReducers, reducers } from './store';
import { CookieModule } from 'ngx-cookie';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';






import { headerModule } from './shared/component/header/header.module';
import { MenuComponent } from './shared/component/menu/menu.component';

import { EmailService } from './service/features/email.service';
import { httpSessionUpdateInterceptor } from './service/common/http-session-update.interceptor';

import { SharedDataService } from './service/common/shared-data.service';
import * as environments from '../json/tdrs-environment.json';
import { TdrsHttpServiceModule } from '../app/service/environment/tdrs-http.service.module';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { HomeComponent } from './feature/home/home.component';
import { TabMenuComponent } from './component/tab-menu/tab-menu.component';
import { ReviewGuidelinesComponent } from './feature/review-guidelines/review-guidelines.component';
import { LoginComponent } from './feature/login/login.component';
import { AuthGuard } from '../app/service/guard/auth.gaurd';
import { HelpcenterComponent } from './feature/helpcenter/helpcenter.component';
import { UserDetailService } from '../app/service/common/user/user-detail.service';
import { StaticContentService } from '../app/service/features/static-content.service';
import { ddSpinnerModule } from '../../src/app/component/general/dd-spinner/dd-spinner.module';
import { TgppFileUploadModule } from '../../src/app/component/tgpp-file-upload/tgpp-file-upload.module';

import { MsalService, MSAL_INSTANCE, MsalGuard, MsalInterceptor, MsalBroadcastService, MSALInstanceFactory, MSALInterceptorConfigFactory } from './msal';
import { InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './msal/constants';
import { HttpAPIInterceptor } from '../app/service/common/http-api.interceptor';
import { NeedHelpComponent } from './feature/need-help/need-help.component';
import { DirectivesModule } from './directive/directives.module';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { AddEditLegalReviewComponent } from './feature/add-edit-legal-review/add-edit-legal-review.component';
import { PreviewPopupComponent } from './feature/preview-popup/preview-popup.component';
import { PreviewPopupService } from './feature/preview-popup/preview-popup.service';


@NgModule({
  declarations: [
    AppComponent,
 
    MenuComponent,
    HomeComponent,
    TabMenuComponent,
    ReviewGuidelinesComponent,
    LoginComponent,
    HelpcenterComponent,
    NeedHelpComponent,
    NotFoundComponent,
    AddEditLegalReviewComponent,
    PreviewPopupComponent,

    
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    appRouting,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    CookieModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: false,
    }),
    EffectsModule.forRoot(effects),
    HttpClientModule,
    MaterialModule,
    MatCardModule,
    MatTableModule,
    tdrsServiceModule.forRoot(),
    FormsModule,
    FlexLayoutModule,
    headerModule,
    
    
    ReactiveFormsModule,
    
    
    ReactiveFormsModule,
    
    
    TdrsHttpServiceModule.forRoot(environments),
    ddSpinnerModule,
    TgppFileUploadModule,
    DirectivesModule,

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect
      } as any
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: tdrsHttpServerErrorInterceptor,
      multi: true,
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    EmailService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpSessionUpdateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAPIInterceptor,
      multi: true
    },
    SharedDataService,
    UserDetailService,
    StaticContentService,
    AuthGuard,
    PreviewPopupService
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
